.footer {
    display: flex;
    width: 100%;
    padding-left: 3vw;
    padding-top: 1vw; // Sağ padding eklendi
    padding-bottom: 1vw; // Sağ padding eklendi
    background: transparent linear-gradient(180deg, #120F0F 0%, #231d1d 97%, #030303 100%) 0% 0% no-repeat padding-box;
    color: white;
    align-items: center; 

}


.footer-section {
    display: flex;
      font-size: 1rem;

    &.left {
      flex: 1;
      color: #484848;
    }

    &.right {
      flex: 1; 
      justify-content: flex-end; 
      margin-right: 1vw;
    }
    
    img {
      margin-left: 10px; 
      margin-right: 1vw;

    }

}
