.thirdcontent {
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  color: black;
  padding-bottom:20vh ;
      margin-left: 10vw;
}

.area {
  margin-top: 0;
  margin-left: 0;
  width: 80vw;
}

.atext {
  font-size: 6vw;
  color: black;
  font-weight: 600;
}

.dashboard {
  font-family: 'Arial', sans-serif;
  margin-top: 10%;
}

.cardchart {
  background-color: white;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 40px;
  padding-top: 4%;
  box-shadow: inset 0px 0px 10px #ccc;
  width: 88%;
  height: 100%;

}

.text {
  padding: 3%;
  text-align: left;
}


.text h1 {
  color: #333;
  font-size: 2vw;
  margin-top: 5%;
}

.text p {
  color: #666;
  font-size: 1.6vw;
}

.custom-tooltip {
  width: auto;
  height: auto;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  box-shadow: inset 0px 0px 2px #9999998f, 0px 0px 10px #9999998f;
  background-color: white;
  padding: 1vw;
}

.label {
  font-size: 1vw;
}

.intro {

  font-size: 1vw;
  margin-left: 1vw;
}

.line,
.linemenu {
  width: 100%;
  height: 0.1vh;
  background-color: #EBEBEB;
}

.linemenu {
  margin-top: 0;
  margin-bottom: 0;
}



.controls{
  width: auto;
  height: auto;
  position: absolute;
  right: 30%;
}
.menu {
  width: 100%;
  height: 40%;
  border: 1px solid #e7e7e7 opacity 1;
  background-color: #fff;
  box-shadow: inset 0px 2px 5px rgba(62, 62, 62, 0.1), 0px 2px 5px rgba(62, 62, 62, 0.2);
  border-radius: 20px;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}
.menu-item {
  background-color: transparent;
  border: none;
  text-align: left;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.2vw;
}