

html, body {
  width: 100%;
  
}

#root, .App {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

