.pricingpage {
    width: auto;
    height: auto;
    padding-top: 15vh;
    background-color: #f5f5f7;

}

.headerpricing {
    padding-left: 10vw;
    width: 90%;

    span {
        font-size: 6.5vh;
        font-weight: 800;
        margin-bottom: 3vh;
        color: black;
    }
}

.contentpricing {
    width: 80%; // Set the width of the contentpricing section to 70% of its parent container
    margin: 0 auto; // This will horizontally center the contentpricing section within its parent
    justify-content: center; // This centers the content horizontally
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    background-color: #fff;
    box-sizing: border-box;
    margin-top: 5vh;

    .headercontent {
        padding-top: 3vh;
        padding-bottom: 6vh;
        padding-left: 4%;
        padding-right: 3vh;
        border-bottom: 1px solid rgb(207, 207, 207);
        display: flex;
        flex-direction: row;
        align-items: center;
        .header {
            font-size: 3.4vh;
            font-weight: bold;
            margin-left: 1vw;
        }

       
    }

    .card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 3vh;
    }

    .info {
        width: 100%;
        padding: 1.5vh 4%;
        border-bottom: 1px solid rgb(207, 207, 207);

        .logo-container {
            margin-bottom: 4vh;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .brand {
                font-weight: bolder;
                font-size: 2.3vh;
                margin-left: 1vw;
            }

            .subtitle {
                margin-left: auto;
                color: #444444;
                font-size: 1.7vh;

            }
        }

      
    }
}

@media (max-width: 600px) {
    .card-container {
        flex-direction: column;
        align-items: center;
    }

    .headerpricing {
        padding-left: 10vw;
        width: 100%;

    }

    .payment {
        display: flex;
        flex-direction: column;

        .features {
            align-items: flex-start !important;
            /* Align items to the start (left) */

        }


    }

    .transaction {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1.7vh;
        color: #444444;
    }
}

@media (max-width: 1400px) {
    .card-container {
        flex-direction: column;
        align-items: center;
    }

    .headerpricing {
        padding-left: 10vw;
        width: 100%;

    }
    .payment {
        display: flex;
        flex-direction: column;

        .features {
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;

            .feature {
                display: flex;
                flex-direction: row;
                padding: 10px;
                font-size: 1.5vh;
            }
        }
    }

}