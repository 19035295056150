.contactsection {
    width: 100%;
    height: auto;
    padding-top: 80px;
    padding-left: 10vw;
    padding-right: 10vw;
    background-color: #F5F5F7;

}

.contactsection .header {
    width: 50%;
    margin-top: 6vh;
    margin-bottom: 4vh;
}

.contactsection .header span {
    font-weight: 800;
    font-size: 3.5rem;
}

.description {
    display: flex;
    flex-direction: column;
}

.description-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;  
     margin-left: 8px;
    font-weight: 600;
    font-size: 1rem;
    font-weight: 700;
    width: 50%;

}

.form {
    width: 50%;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.form-row {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    width: auto;
    gap: 2vw;
}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group label {
    font-weight: 600;
    font-size: 0.8rem;
}

.form-group input {
    height: auto;
    margin-top: 0.8vh;
    padding-left: 0.5vw;
    font-size: 0.8rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 0.5;
    padding: 4% 3%;
}

.form-group select {
    height: 4vh;
    width: 100% !important;
    margin-top: 0.8vh;
    padding-left: 0.5vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    font-size: 0.8rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 0.5;
    color: #707070;
    border-radius: 5px;
}

.form-group input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.form-group button {
    margin-top: 1.5vh;
    background-color: #265DF6;
    border-radius: 30px;
    border-width: 0;
    height: 4.5vh;
    color: white;
    font-size: 1rem;
    font-weight: 500;
}

.form-group:last-child {
    margin-right: 0;
}

.form-group textarea {
    margin-top: 0.8vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 0.5;
    padding: 0.5vw;
    font-size: 0.8rem;

}

@media screen and (max-width: 480px) {
    .contactsection .header {
        width: 100%;
        margin-top: 6vh;
        margin-bottom: 6vh;
    }

    .contactsection .header span {
        font-size: 2rem;
    }

    .contactsection .description-item span {

        font-size: 0.8rem;
    }

    .form-group select {
        width: 200% !important;

    }

    .form-group textarea {
      width: 200%;
    }

    .form-group button {
       width: 200%;
    }

}