.secondcontent {
    height: auto;
    flex-direction: column;
    display: flex;
    justify-content: center !important;
    padding-bottom: 20vh;
    padding-top: 20vh;
}

.secondcontent .textarea {
    margin-left: 10%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
}

.secondcontent .textheader {
    width: 80vw;
    font-size: 2.4vw;
    color: black;
    font-weight: 800;
}

.secondcontent .textsubtitle {
    width: 80vw;
    font-size: 1.8vw;
    color: rgb(63, 63, 63);
    font-weight: 400;
    margin-top: 1vh;
}

.secondcontent .cardareacontent {
    display: flex;
    flex-direction: row;
    margin-left: 10vw;
    margin-top: 6vh;
}

.cardareaone {
    width: 38%;
    height: auto;
    box-shadow: inset 0px 3px 6px #a4a4a429, 0px 3px 6px #a4a4a429;
    border-radius: 3vw;
    padding: 2%;
    display: flex;
    flex-direction: column;

    .box-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 9%;
    }

    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1%;
        margin-right: 0.5vw;
        margin-left: 0.5vw;
        padding: 0.5vw;
        width: 40vw; // Kutu genişliği artırılıyor
        height: 8vh; // Kutu yüksekliği artırılıyor
        border-radius: 20px;
    }

}

.cardareasecond {
    width: 45%;
    height: auto;
    box-shadow: inset 0px 3px 6px #a4a4a429, 0px 3px 6px #a4a4a429;
    border-radius: 3vw;
    margin-left: 5%;
    padding-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 50%;
        padding-top: 4%;

    }
}