@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700&display=swap');
@import '~bootstrap/scss/bootstrap';

.navbar {
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    padding-left: 10vw;
    .navbar-brand {
        color: #ffffff;
        font-size: x-large;
    }
    .nav-link {
        color: #ffffff;
        font-size: small;
        padding: 0.4vh 0.4vw;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        span{
            margin-right: 0.5vw;
        }
    }

    .custom-item {
        color: #8E8E8E;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        background-color: #000000 !important;
        font-size: 1.5vh;
        width: 100%;
    }
    
    .custom-nav-dropdown .dropdown-toggle::after,
    .custom-nav-dropdown-int .dropdown-toggle::after {
        display: none; /* Varsayılan oku gizle */
      }
      
    .custom-nav-dropdown .dropdown-toggle,
    .custom-nav-dropdown-int .dropdown-toggle {
        position: relative; 
    }
    .custom-item span:first-child {
        color: white;
        margin-bottom: 0.2vh;
    }

    .custom-nav-dropdown .dropdown-toggle:hover,
    .custom-nav-dropdown .dropdown-item:hover,
    .custom-nav-dropdown-int .dropdown-toggle:hover,
    .custom-nav-dropdown-int .dropdown-item:hover {
        color: rgb(186, 186, 186) !important;
    }

    .custom-nav-dropdown .dropdown-toggle:focus,
    .custom-nav-dropdown .dropdown-toggle:active,
    .custom-nav-dropdown-int .dropdown-toggle:focus,
    .custom-nav-dropdown-int .dropdown-item:active{
        color: rgb(186, 186, 186) !important;
    }

    .custom-nav-dropdown .dropdown-item:focus,
    .custom-nav-dropdown .dropdown-item:active,
    .custom-nav-dropdown-int .dropdown-item:focus,
    .custom-nav-dropdown-int .dropdown-item:active  {
        color: rgb(186, 186, 186) !important;
    }



    .custom-nav-dropdown .dropdown-menu {
        background-color: black !important;
        border: none !important;
        border-radius: 20px !important;
        border: 1px solid #707070 !important;
        margin-top: 1vh;
        margin-bottom: 1vh;

    }

    .custom-nav-dropdown .dropdown-item {
        background-color: black !important;
        border-radius: 20px !important;
    }

    .custom-nav-dropdown-int .dropdown-menu {
        border-radius: 20px !important;
        border: 1px solid #e4e4e4 !important;
        margin-top: 1vh;

    }

    .custom-nav-dropdown-int .dropdown-item {
        border: none !important;
        background-color: #ffffff !important;
        border-radius: 20px !important;
        border: 1px solid #707070;

    }
    .custom-nav-dropdown-int{
        border: 1px solid #707070 !important;

    }
    .custom-international {
        flex-direction: row !important;
        background-color: #ffffff !important;
        border-radius: 30px !important;
        display: flex;
        white-space: nowrap !important;
        font-family: 'Source Serif Pro', serif;
        
        .custom-item {
            color: #000000 !important;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            margin-left: 0;
            font-family: 'Source Serif Pro', serif;
            font-weight: 400;
            background-color: transparent !important;
        }
    }

}
.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1vh;
    margin-bottom: 1vh;
    gap: 10px;

    .service-card {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-right: 2vw;
        align-self: center;
    }

      
   
  }
  