
.backoffice{
    width: auto;
    height: auto;
    padding-top: 15vh;
    background-color: #f5f5f7;
}

.headerpay{
    display: flex;
    flex-direction: column;
    padding-left: 15vw;

    span:first-child{
        font-size: 3vw;
        font-weight: 800;
        margin-bottom: 2vh;
        color: black;
    }
    span:nth-child(2) {
        font-size: 1.5vw;
        font-weight: 400;
        width: 70vw;
        color: black;
    }
    span:nth-child(3) {
        font-size: 1.5vw;
        font-weight: 400;
        width: 70vw;
        color: black;
        margin-top: 1.5vh;
        margin-bottom: 1.5vh;
    }
    a{
        max-width: 16vw;
        background-color: rgba(64,107,208,255);
        border: 0;
        border-radius: 20px;
        font-size: 1vmax;
        font-weight: 500;
        padding-left: 1vw;
        padding-right: 1vw;
        padding-top: 0.5vh;
        padding-bottom: 0.5vh;
        color: black;
        text-decoration: none;
        text-align: center;
    }
}


.imagearea{
    border: 4px solid #9b9b9b;
    border-radius: 20px;
    overflow: hidden;  
}
.video-container {
    position: relative;
    width: 100vw;   
    height: 100vh; 
    overflow: hidden;
  }
  
  .fullscreen-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #74CB33;
    font-size: 3rem; 
    text-align: center;
    z-index: 10; 
  }

  .swiper-container {
    margin-right: calc(50% - 550px);
  }
  
.swiper-button-next, .swiper-button-prev {
    color: #606060 !important; 
    background-color: rgba(137, 137, 137, 0.5);
    width: 3% !important;
    border-radius: 50px !important;
    margin-left: 10vw !important;
    margin-right: 10vw !important;
}

.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 20px !important; 
    padding: 2% !important;
}
.secondcarousel {
    width: 100%;
    justify-content: center;
    border-radius: 2vmax;
    overflow: hidden;
    position: relative;
}
@media screen and (max-width: 480px) {
    .header{
        span:nth-child(2) {
            font-size: 4vmax;
            font-weight: 800;
            width: 80vw;
            color: black;
        }
    }
}

