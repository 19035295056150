
.image {
    width: 100%;
    height: auto; 
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    box-sizing: border-box; 
}   
.card{    
    display: flex;
    position: absolute; 
    bottom: 50%; /* Dikeyde ortalamak için */
    left: 50%; /* Yatayda ortalamak için */
    transform: translate(-50%, 50%);

    width: 36%;    
    height: auto;

    background-color: white;
    border-radius: 1.4vmax;
    padding: 4% 0vw 4% 0vw;
  

    h3 {
        font-weight: 600;
        font-size: 2vw;
        text-align: center;
        width: 100%;
        max-width: 70%;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 6%;
    }
    button {
        background-color: #000000;
        width: 100%;
        max-width: 50%;
        color: white;
        border: 0;
        border-radius: 3vmax;
        font-size: 1.2vw;
        font-weight: 600;
        text-align: center;
        margin: 0 auto; // This will horizontally center the contentpricing section within its parent
        justify-content: center; // This centers the content horizontally
        padding: 2% 0 2% 0;
        margin-bottom: 6%;
    }

   
} 
.cartitems{
    display: flex;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    width: 70%;
    margin-top: 1%;
    margin-bottom: 1%;
}