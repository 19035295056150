.carouseltwo {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
}

.navbard {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;

    background-color: transparent;
    border-bottom: 1px solid #707070;
    padding: 2%;

    .navbar-brand {
        display: flex;
        margin-left: 3vw;

        a {
            color: white;
            font-weight: 400;
            text-decoration: none;
            font-size: 1.4vw;
            font-family: "Helvetica Neue", Helvetica, sans-serif;
        }
    }

    .navbar-link {
        display: flex;
        align-self: center;
        justify-content: center;
        margin-right: -13%;

        span {
            color: white;
            display: flex;
            font-size: 1.2vw;
            margin-left: 1%;
            margin-right: 2%;
            align-self: center;
        }


    }

}

.carouselcontent {
    width: auto;
    position: absolute;
    top: 14%;
    left: 0;
    right: 0;
    margin-left: 7%;
    margin-right: 7%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        color: white;
        display: flex;
        flex-direction: row;
        font-size: 1.2vw;
    }

    label {
        color: #75D960;
        font-size: 1.2vw;
        margin-left: 6px;
    }
}

.chart {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    /* Pozisyonu relative yapın */
    top: 33%;
}

.datas {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    /* Pozisyonu relative yapın */
    top: 55%;
}


.payment-table {
    width: 88%;
    border-collapse: collapse;
    background: transparent;
    color: #FFFFFF;

    thead {
        th {
            font-weight: bold;
            border-bottom: 1px solid #333;
            text-align: center;
            font-size: 1.4vw;

            &:first-child {
                text-align: left;
            }
        }
    }

    tbody {
        td {
            text-align: center;
            border-bottom: 1px solid #333;
            font-size: 1.3vw;

            &:first-child {
                text-align: left;
            }
        }
    }

    .date {
        text-align: right;
        white-space: nowrap;
    }

    .paid {
        color: #0f0;
        white-space: nowrap;
        font-size: 1.2vw;

        div {
            background-color: #293726;
            border-radius: 30px; // Köşeleri yuvarlaklaştır
            padding-top: 0.6vh;
            padding-bottom: 0.6vh;
            padding-left: 0.6vh;
            padding-right: 0.6vh;
            svg {
                color: #0f0; // İkonun rengini ayarla
                margin-right: 9px; // İkon ve metin arasında boşluk bırak
            }

        }

    }
}