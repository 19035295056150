.contents {
    width: 100%;
    height: auto;
    flex-direction: column; // Stack children vertically
    justify-content: space-between; // Space between top content and bottom content
    height: auto;
    background: transparent linear-gradient(180deg, #120F0F 0%, #2e2626 97%, #030303 100%) 0% 0% no-repeat padding-box;

    .textarea {
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        width: 90%;
        height: auto;
        margin-bottom: 10vh;
        padding-right: 10vw;
        padding-left: 10vw;
        span {
            color: white;
            font-size: 3.5vmax; // Ekran genişliğine bağlı dinamik font boyutu
            font-weight: 800;
        }
        span:nth-child(2) {
            font-size: 2.8vh;
            font-weight: 400;
            width: 70vw;
        }

        .buttonarea {
            margin-top: 3vh;
            height: 100%;
            width: 100%;
            button {
                background-color: #292929;
                color: white;
                border: 0;
                border-radius: 10vmax;
                font-size: 1.8vh;
                font-weight: 600;
                padding: 1vh 2vw;
                &:hover {
                    background: transparent linear-gradient(180deg, white 0%, #383838 97%, #2d2d2d 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
    }

    .firstcarousel {
        width: 100%;
        height: auto;
        justify-content: center;
        border: 2px solid #707070;
        border-radius: 2vmax;
        overflow: hidden;
    }


    .secondcarousel {
        width: 100%;
        justify-content: center;
        border: 2px solid #707070;
        border-radius: 2vmax;
        overflow: hidden;
    }
}

@media screen and (max-width: 480px) {
    .contents{
            height: auto;

    }
}
  